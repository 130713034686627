.root {
  display: flex;
  align-items: center;
}

.letUsTalkButton {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  
}
.navLink,
.navButton {
  padding-inline: calc(var(--mui-spacing) * 3);
  align-self: stretch;
  transition: all 300ms ease;
}

.navLink {
  display: flex;
  align-items: center;
}

.navButton {
  border-radius: 0;
}

.navLink:hover,
.navButton:hover {
  background-color: var(--design-color-primary-main);
}
.activeLink {
  background-color: var(--design-color-primary-main);
}

.popoverOpenButton {
  gap: calc(var(--mui-spacing) * 1.5);
  margin-left: calc(var(--mui-spacing) * 1);
  margin-right: calc(var(--mui-spacing) * 1);
}

.popoverContent {
  list-style: none;
  padding: calc(var(--mui-spacing)*3) calc(var(--mui-spacing) * 3);
  margin: 0;
}

.popoverContent > li:not(:last-child) {
  margin-bottom: calc(var(--mui-spacing) * 1.5);
}

.popoverContent > li > a {
  display: flex;
  align-items: center;
  gap: var(--mui-spacing);
  font-size: var(--mui-typography-h6-fontSize);
}

.popoverContent > li > a:hover {
  text-decoration: underline;
}

.drawerDivider {
  height: 1px;
  background-color: var(--design-color-primary-light);
}