.root {
  padding-top: calc(var(--mui-spacing) * 4);
  padding-bottom: calc(var(--mui-spacing) * 2);
  background-color: var(--design-color-primary-main);
  color: var(--design-color-secondary-main);
  font-family: var(--design-headerFontFamily);
}
.addressContainer {
  display: flex;
  flex-direction: column;
  gap: calc(var(--mui-spacing) * 5);
}

.socialNetworkLinks {
  color: var(--design-color-secondary-light);
  text-align: center;
}

.extraLinks {
  color: var(--design-color-secondary-light);
  text-align: center;
}

.divider {
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: var(--design-color-secondary-light);
}

@media (--mui-breakpoint-sm-up) {
  .socialNetworkLinks,
  .extraLinks {
    text-align: unset;
  }

  .divider {
    height: 1px;
    overflow: hidden;
    background-color: var(--design-color-secondary-light);
    width: 100%;
  }
}

@media (--mui-breakpoint-md-up) {
  .root {
    padding-top: calc(var(--mui-spacing) * 12);
  }
  .addressContainer {
    flex-direction: row;
    gap: calc(var(--mui-spacing) * 10);
  }
}

.cardText{
  font-family: var(--design-bodyFontFamily);
}