.root {
  background-color: var(--design-color-primary-main);
  padding: calc(var(--mui-spacing) * 4) calc(var(--mui-spacing) * 4);
}
.container {
  display: flex;
  flex-direction: column;
  gap: calc(var(--mui-spacing) * 2);
}

.letUsTalkButton {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: calc(var(--mui-spacing) * 8);
}



.card {
  background-color: var(--design-color-common-white);
  padding: calc(var(--mui-spacing) * 2) calc(var(--mui-spacing) * 3);
  margin-top: calc(var(--mui-spacing) * 4);
  border-radius: calc(var(--mui-spacing) * 2);
  box-shadow: 0 0 calc(var(--mui-spacing) * 2) 0 rgb(0, 22, 63, 0.15);
}

.cardText {
  margin-top: calc(var(--mui-spacing) * 2);
}

.cardButton {
  color: var(--design-color-common-white);
  background-color: var(--design-color-primary-main);
}

@media (--mui-breakpoint-md-up) {
  .root {
    padding: calc(var(--mui-spacing) * 8) calc(var(--mui-spacing) * 8);
  }
  .container {
    flex-direction: row;
  }

  .card {
    margin-top: 0;
  }

  .card,
  .welcome {
    flex: 1;
  }
}

@media (--mui-breakpoint-sm-up) {
  .readMore {
    display: initial;
  }
}
.twoReactangleContainer {
  position: relative;
  width: 100%;
  height: 300px;
}

.rectangle1 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50px;
  background-color: var(--design-color-action-dark);
  clip-path: polygon(5% 10%, 95% 5%, 100% 85%, 0% 95%);
  z-index: 1;
}

.rectangle2 {
  position: absolute;
  width: 80%;
  height: 100%;
  top: -50px;
  background-color: var(--design-color-action-dark);
  clip-path: polygon(0% 10%, 95% 5%, 100% 85%, 0% 95%);
  transform: rotate(-5deg) translate(-20px, 20px);
  z-index: 0;
}

.textContent {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  top: 30%;
}

.textContent h1 {
  font-size: 36px;
  font-weight: bold;
}

.textContent p {
  font-size: 18px;
}

.button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: white;
  color: #0066dd;
  border: 2px solid white;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
}

.button:hover {
  background-color: #004bb7;
  color: white;
}
