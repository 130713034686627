.root {
  padding: calc(var(--mui-spacing) * 6) 0;
}

.container {
  padding: calc(var(--mui-spacing) * 2) calc(var(--mui-spacing) * 4);
  border-radius: calc(var(--mui-spacing) * 2);
  border-bottom-left-radius: 0;
  background: var(--mui-palette-mono-dark);
}

.card {
  display: flex;
  flex-direction: column;
  gap: calc(var(--mui-spacing) * 2);

  background-color: var(--design-color-common-white);
  padding: calc(var(--mui-spacing) * 3);
  border-radius: calc(var(--mui-spacing) * 3);
  border-bottom-left-radius: 0;
}

.button {
  /* align-self: flex-end; */
  border-radius: var(--design-borderRadius-sm);
}

@media (--mui-breakpoint-md-up) {
  .container {
    min-height: calc(var(--mui-spacing) * 66);
    padding: calc(var(--mui-spacing) * 6) calc(var(--mui-spacing) * 6);
    background: var(--mui-palette-primary-light)
      url("../../images/career-banner.png") no-repeat scroll center/85%;
    background-position-x: 100%;
  }

  .card {
    max-width: 50%;
  }
}

@media (--mui-breakpoint-lg-up) {
  .container {
    padding: calc(var(--mui-spacing) * 4) calc(var(--mui-spacing) * 6);
  }
}

.divider {
  height: 1px;
  background-color: var(--design-color-primary-light);
}

.section {
 margin-block: calc(var(--mui-spacing) * 8);
}