.root {
  display: flex;
  align-items: center;
}

.drawer {
  list-style: none;
  padding: calc(var(--mui-spacing) * 1.5) calc(var(--mui-spacing) * 3);
  margin: 0;
}

.drawer > li {
  margin-top: calc(var(--mui-spacing) * 1.5);
}

.drawer > li > a {
  display: flex;
  gap: calc(var(--mui-spacing) * 1.5);
  align-items: center;
  font-weight: var(--mui-typography-h6-fontWeight);
  font-family: var(--mui-typography-h6-fontFamily);
  font-size: var(--mui-typography-h6-fontSize);
}

.drawerHead {
  display: flex;
  align-items: center;
  flex-flow: row-reverse wrap;
  gap: var(--mui-spacing);
}

.drawerDivider {
  height: 1px;
  background-color: var(--design-color-primary-light);
}

@media (--mui-breakpoint-sm-up) {
  .root {
    gap: calc(var(--mui-spacing) * 2);
  }
}
