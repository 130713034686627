.root {
  display: flex;
  flex-direction: column;
  gap: var(--mui-spacing);
  cursor: pointer;
}

.leftAlignedButton {
  justify-content: left;
  text-align: left;
  display: flex;
}


@media (--mui-breakpoint-sm-up) {
 .root {
  flex-direction: row;
 }
}