.root {
  background-color: var(--design-color-primary-main);
  height: 100vh;
}

.bannerImage {
  padding: calc(var(--mui-spacing) * 2) calc(var(--mui-spacing) * 4);
  border-radius: calc(var(--mui-spacing) * 2);
  border-bottom-left-radius: 0;
  background: var(--mui-palette-mono-dark);
  width: 100%;
  background: var(--design-color-primary-main);
  background-image: url("../../images/portrait-spiritual-awakening.jpg");
  flex: 1 1 65%;
}

@media (--mui-breakpoint-md-up) {
  .bannerImage {
    min-height: 100vh;
    background-size: auto 100%;
    -webkit-mask-image: linear-gradient(
      to right,
      transparent 0%,
      black 25%,
      black 75%,
      transparent 100%
    );
    mask-image: linear-gradient(
      to right,
      transparent 0%,
      black 25%,
      black 75%,
      transparent 100%
    );
  }
}

@media (--mui-breakpoint-lg-up) {
  .bannerImage {
    padding: calc(var(--mui-spacing) * 4) calc(var(--mui-spacing) * 6);
  }
}

.container {
  display: flex;
  gap: 100px;
}
.animations {
  padding-block: 100px;
  flex: 1 1 30%;
}

@media (--mui-breakpoint-md-down) {
  .container {
    flex-direction: column;
    gap: 30px;
    height: 70vh;
  }
  .bannerImage {
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    border-radius: 0;
  }
  .animations {
    padding-inline: 30px;
    flex: 1 1 100%;
    padding-block: 0;
  }
  .root {
    height: 50vh;
    padding: 0;
  }
}
