.root {
  box-shadow: 0 0 15px 0 rgb(0, 22, 63, 0.15);
  height: 68px;
  display: flex;
  align-items: stretch;
  padding-inline: var(--mui-spacing);
  z-index: 1; 
  justify-content: space-between;
}

.root > * {
  flex-grow: 1;
}

@media (--mui-breakpoint-sm-up) {
  .root {
    padding-inline: calc(var(--mui-spacing) * 2);
  }
}

@media (--mui-breakpoint-md-up) {
  .root {
    padding-inline: calc(var(--mui-spacing) * 50);
  }
}
